import Layout from '../components/layout'
import React from 'react'
import Link from 'gatsby-link'
import BodyClassName from 'react-body-classname';
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import "animate.css/animate.css";
import ScrollAnimation from 'react-animate-on-scroll';

export default class ResumePage extends React.Component {
  render() {
    return (
      <Layout>
      <PageTransition>
      <BodyClassName className="resume">
      <div className="page">

          <article className="article">
            <ScrollAnimation animateIn="fadeInUp2" >
          <h1>Resumé</h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp2" >
          <section className="introSection">
          <p>I am a seasoned design leader with over twenty years of experience. I’ve worked for fast-growing tech companies, boutique design agencies, and non-profit organizations.</p>
          <p> Everywhere I’ve worked, I’ve been able to help build strong and diverse design teams. I am a manager but prefer to work alongside my team, rather than to direct from a pedestal. I enjoy the collaboration and energy that comes from working together. </p>
          <p>I have a pragmatic approach to user-centered design and strive for a balance between customer value and business needs. I’m self-driven and love to work on tough problems that make a difference in the people’s lives.</p>
          </section>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp2" >


          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp2" >
          <section className="timelineSection mt-60">
          <h2 className="ruled">Work Experience</h2>
          <ul className="timeline">
          <li className="timelineTail">
              <div className="timelineContents">
                <div className="description">
                  <div className="bullets">
                  <h2>Lucid Software</h2>
                  <p className="caption">Salt Lake City, Utah,  Oct 2020—Present</p>
                  <h3>Sr. Director, Design Operations</h3>                  
                  <ul>
                    <li>Manage design teams building and maintaining <a href="http://instructure.design">Instructure UI</a>, the design system used in Canvas LMS Platform.</li>                   
                  </ul>
                </div>
                <div className="quotes">
                  
                  </div>
                </div>
              </div>
              <div className="timelineDate">TODAY</div>
              </li>
          <li className="timelineTail">
              <div className="timelineContents">
                <div className="description">
                  <div className="bullets">
                  <h2>Instructure</h2>
                  <p className="caption">Salt Lake City, Utah,  Aug 2019—Jun 2020</p>
                  <h3>Sr. Director, Design Operations</h3>                  
                  <ul>
                    <li>Manage design teams building and maintaining <a href="http://instructure.design">Instructure UI</a>, the design system used in Canvas LMS Platform.</li>                   
                  </ul>
                </div>
                <div className="quotes">
                  
                  </div>
                </div>
              </div>
              <div className="timelineDate">TODAY</div>
              </li>
            <li className="timelineTail">
              <div className="timelineContents">
                <div className="description">
                  <div className="bullets">
                  <h2>Ancestry</h2>
                  <p className="caption">Lehi, Utah,  Nov 2011—Aug 2019</p>
                  <h3>Sr. Director, User Experience</h3>
                  <h3>Director, User Experience</h3>
                  <h3>Sr. Product Designer </h3>
                  <ul>
                    <li>Managed design teams releasing dozens of innovative features for web and mobile products, from concept through implementation.</li>
                    <li>Created a design system for Ancestry products. Worked closely with product management and front-end development to improve consistency across all products.</li>
                    <li>Created a design methodology and tools to document customer journey and improve the product with user-centered metrics.</li>
                    <li>Recruited and hired designers, growing design team from 5 to a diverse team of over 30.</li>
                  </ul>
                </div>
                <div className="quotes">
                  <blockquote className="quotes">
                    <em>I have really enjoyed working with John. He is extremely thoughtful and takes great pride in his craft. Not only is he extremely talented, but he is a real pleasure to work with. I would definitely hire John.</em><span className="quoteCredit">
                    <strong>Kendall Hulet,</strong> CEO at Cake, former Sr. VP Product at Ancestry</span>
                  </blockquote>
                    <blockquote className="quotes">
                      <em>You will see lots of articles about hiring designers that say stuff like, “Full-stack designers don’t exist! Quit looking for unicorns! Respect specialization!” That’s because the authors haven’t worked with John. From visual design, customer research, solving intense interaction design problems, reworking challenging information architectures, writing, all the way to coding up design tools that give the team superpowers… he really does it all and does it all really well. If I didn’t love the guy so much I would hate him out of jealousy.</em><span className="quoteCredit"><strong>Josh Penrod</strong>, CPO at Podium, Former VP of User Experience at Ancestry</span>
                    </blockquote>
                    <blockquote>
                      <em>If they were smart, they would put you in charge</em><span className="quoteCredit"><strong>A.J. Jacobs</strong>, Best Selling Author, It’s All Relative</span>
                    </blockquote>
                  </div>
                </div>
              </div>
              <div className="timelineDate">2019</div>
              </li>
              <li className="timelineTail">
                <div className="timelineContents">
                  <div className="description">
                    <div className="bullets">
                    <h2>Academy of Art University</h2>
                    <p className="caption">San Francisco, Feb 2006—Nov 2011</p>
                    <h3>Adjunct Faculty</h3>
                    <ul>
                    <li>Taught the course “Advanced Scripting”-teaching art students advanced scripting concepts using Adobe Flash(RIP).</li>
                    <li>Taught group directed study courses to mentor and help students working on their MFA projects.</li>
                    </ul>
                    </div>
                    <div className="quotes">
                    </div>
                  </div>
                </div>
                <div className="timelineDate">2011</div>
              </li>
              <li className="timelineTail">
                <div className="timelineContents">
                  <div className="description">
                    <div className="bullets">
                    <h2>Family Search, Church of Jesus Christ of Latter-Day Saints</h2>
                    <p className="caption">Salt Lake City, Utah, Feb 2006—Nov 2011</p>
                    <h3>Lead Interaction Designer</h3>
                    <h3>Sr. Interaction Designer</h3>
                    <ul>
                    <li>I helped grow the team from 5 to over 40 interaction designers.</li>
                    <li>I led the design of an entirely new online store with 17,000 products. The effort included design and product photography</li>
                    <li>Designed website and financial application to assist in the distribution and payment of an international educational assistance program.</li>
                    <li>Established “Design Excellence” program to help train and improve the skills of a design team.</li>
                    <li>Compiled and developed guidelines and best practices for accessible design</li>
                    </ul>
                    </div>
                    <div className="quotes">
                    <blockquote>
                      <em>
                    John is an excellent designer and team player. Worked with him on a project for the design and implementation of a Web portal for the Perpetual Education Fund (PEF)…His designs were top notch …his documentation was excellent, and his estimates to complete work were spot on. John is a very talented designer that I highly recommend.</em><span className="quoteCredit"><strong>Raymond Babbit</strong>, Program Manager</span>
                    </blockquote>
                    <blockquote>
                    <em>You rarely find this much talent and expertise in a single individual. John is exceptional at strategizing, concepting, articulating, managing, and executing top-notch user experience design. He's a walking wiki on the theory of design and user experience. He collaborates really well, he's affable and friendly to work with, and somehow he manages to do all this while expecting nothing short of great design from those around him. He inspires team members and peers to deliver high-quality product experiences. In short, John is pretty fantastic at what he does</em><span className="quoteCredit"><strong>Cameron Moll</strong>, Author, Speaker, Design Leader  at Facebook</span></blockquote>
                    </div>
                  </div>
                </div>
                <div className="timelineDate">2011</div>
              </li>
              <li className="timelineTail">
                <div className="timelineContents">
                  <div className="description">
                    <div className="bullets">
                    <h2>Weber State University</h2>
                    <p className="caption">Ogden, Utah, Feb 2006—Nov 2011</p>
                    <h3>Adjunct Faculty</h3>
                    <ul>
                    <li>I taught various advanced graphic design, typography, and web design courses over a period of 5 years.</li>
                    </ul>
                    </div>
                    <div className="quotes">
                      <blockquote>
                      <em>John was one of my favorite professors at Weber State University. His ability to clearly teach and translate interactive design is proof that he has a great understanding of the field.</em><span className="quoteCredit"><strong>Adam Yost</strong>, Former Student</span>
                      </blockquote>
                    </div>
                  </div>
                </div>
                <div className="timelineDate">2011</div>
              </li>
              <li className="timelineTail">
                <div className="timelineContents">
                  <div className="description">
                  <div className="bullets">
                  <h2>Humaniz Interactive</h2>
                  <p className="caption">Salt Lake City, Utah, Oct 1999–Feb 2006</p>
                    <ul>
                      <li>I helped grow the company from a two-person team in 1999 to a full-service agency with more than 15 employees.</li>
                      <li>Provided brand identity design, web design & development, flash animation, and specialized software development for dozens of clients.</li>
                      <li>During my tenure, Humaniz became one of the top boutique agencies in Salt Lake City with local and international clients.</li>
                      <li>In addition to contributing individually to design work, I filled many roles, including sales, account management, project management, company administration, and software development.</li>
                    </ul>
                  </div>
                  <div className="quotes">
                  </div>
                  </div>
                </div>
                <div className="timelineDate">2006</div>
              </li>
              <li className="">
                <div className="timelineContents">
                  <h2>Iomega®</h2>
                  <p className="caption">Roy, Utah, Oct 1996–Oct 1999</p>
                  <h3>User Interface Designer/Graphics Coordinator</h3>
                  <div className="description">
                  <div className="bullets">
                  <ul>
                    <li>I provided the interactive design for international products, marketing strategy, and innovation efforts.</li>
                    <li>I oversaw the authoring of CD-ROMs for installation and instructions for the wildly popular Zip® and Jaz®  computer storage products.</li>
                    <li>I was involved in the research & development for several innovative product design efforts, including an interface for an early digital camera.</li>
                    <li>Designed the interface for the first commercial digital music encoding app released in 1997 (RecordIt).</li>
                  </ul>
                  </div>
                  <div className="quotes"></div>
                  </div>
                </div>
                <div className="timelineDate">1999</div>
              </li>
          </ul>
          <h2 className="ruled">Education</h2>
          <ul className="timeline">
          <li className="timelineTail">
            <div className="timelineContents">
              <h2>MFA, Web Design and New Media</h2>
              <h3>Academy of Art University</h3>
              <p className="caption">San Francisco, California, December 2008</p>
              <div className="description">
                <div className="bullets">
                  <p>After 10 years working in the design industry, I decided to pursue a terminal degree (Master of Fine Arts) in design. I did this to broaden my knowledge and perspective in design, and to explore artistic interests outside of my career.</p>
                </div>
              </div>
            </div>
            <div className="timelineDate">2008</div>
          </li>
            <li className="">
              <div className="timelineContents">
                <h2>BFA, Visual Communications</h2>
                <h3>Weber State University</h3>
                <p className="caption">Ogden, Utah, December 1996</p>
                <div className="description">
                  <div className="bullets">
                  </div>
                </div>
              </div>
              <div className="timelineDate">1996</div>
            </li>
          </ul>
          </section>
          </ScrollAnimation>
        </article>
      </div>
      </BodyClassName>
    </PageTransition>
    </Layout>
    )
  }
}
